import { IS_LOCAL_DEV } from './environment'
import {
  GamerStatus,
  HostVisibility,
  MatchInfo,
  MatchStatus,
  ModelPart,
  PlaylistInfo,
  PlayStatus,
  PlayStatusTrackInfo,
  ScoreDelta,
  TrackInfo,
} from '../types'

export const MAX_LOCAL_BLASTERS = 2

export const LAT_SEMVER_0_0_0 = '0.0.0'
export const DEFAULT_SEMVER = (versionString?: string) => {
  const versionParts = versionString ? versionString.split(' ') : [] // TODO check for 'lat '
  const semverPart = versionParts.length === 2 ? versionParts[1] : ''
  return semverPart || LAT_SEMVER_0_0_0
}
export const LAT_SEMVER_0_1_0 = '0.1.0'
export const LAT_SEMVER_0_2_0 = '0.2.0'
const CURRENT_LAT_VERSION = `lat ${LAT_SEMVER_0_2_0}`
const SERVER_URL = IS_LOCAL_DEV ? 'http://localhost:5000' : ''
const TERMS_OF_USE_URL = IS_LOCAL_DEV ? '' : '/terms?embed=true' // infinite loop to serve this locally before user has agreed!
const QUICKSTART_URL = `${SERVER_URL}/quickstart?embed=true`
const USER_GUIDE_URL = `${SERVER_URL}/guide`
const PLEASE_VERIFY_EMAIL_URL = `${SERVER_URL}/verify-email`
const PLAYER_ICONS_PATH = `${SERVER_URL}/img/icon`
const API_PATH = `${SERVER_URL}/api`
const S3_REGIONS = ['us-east-1']
const API_PATH_S3_AUTH = `${API_PATH}/user/alias`
const API_PATH_SWITCH_USER_ALIAS = `${API_PATH}/user/switch-alias`
const CHECK_USERNAME_AVAILABILITY_URL = `${API_PATH}/user/check-alias`
const API_PATH_SPOTIFY_LOGIN = `${API_PATH}/spotify/login`
const API_PATH_FETCH_SPOTIFY_TOKEN = `${API_PATH}/spotify/token`
const BLASTER_URL_PREFIX = 'play'
const DEFAULT_AUDIO_TYPE = 'mp3'
const DEFAULT_IMAGE_TYPE = 'png'
const DEFAULT_AUDIO_FILE_TYPE = 'audio/mp3'
const DEFAULT_IMAGE_FILE_TYPE = 'image/png'
const INIT_USERNAME_URL = `${API_PATH}/user/new-alias`
const CHECK_USERNAME_URL = `${API_PATH}/user/check`
const URL_PLACEHOLDER = 'default'
const PART_ID_PREFIX = 'item'
const MAX_URL_LENGTH = 500

const MAX_USER_ALIASES = 5
const rankEnum = ['perfect', 'excellent', 'good', 'bad']
export enum Rank {
  PERFECT = 'perfect',
  EXCELLENT = 'excellent',
  GOOD = 'good',
  BAD = 'bad',
}
const HOST_VISIBILITY_PLAY: HostVisibility = 'play'
const HOST_VISIBILITY_DEMO: HostVisibility = 'demo'
const HOST_VISIBILITY_HIDE: HostVisibility = 'hide'

const DEFAULT_OWNED_MATCH_TITLE = 'My Playlists'
const RELEASE_NAME = 'Lyric Blaster'
const HOW_TO_PROMPT = `How to ${RELEASE_NAME}...`

export enum Pane {
  STATUS,
  SETTINGS,
  PLAY,
  SIDEBAR,
  PLAY_CONTROLS,
  FOOTER,
}
export const PaneClasses = {
  0: 'statusSidebar',
  1: 'headerWrapper',
  2: 'interactionWrapper',
  3: 'socialContainer',
  4: 'playControlsWrapper',
  5: 'pageFooter',
}

const defaultScoreCounters = () => ({
  perfect: 0,
  excellent: 0,
  good: 0,
  bad: 0,
})
const DEFAULT_SLUG = 'default'
export const defaultPlayStatusTrackInfo = (
  trackOrMatchOrPlaylistSlug = DEFAULT_SLUG
): PlayStatusTrackInfo => ({
  song: trackOrMatchOrPlaylistSlug,
  trackDuration: 0,
  numElements: 0,
  maxScore: 0,
})
const defaultMatchScore = (
  trackInfo: PlayStatusTrackInfo = defaultPlayStatusTrackInfo()
): PlayStatus => {
  return {
    ...trackInfo,
    gamerId: '',
    counters: defaultScoreCounters(),
    errorCount: 0,
    doneCount: 0,
    numLaps: 0,
    score: 0,
    scoreSeconds: 0,
    topScore: 0,
    topScoreRank: 'bad',
    isPlaying: false,
    timestamp: Date.now(),
  }
}

export const defaultScoreDelta = (): ScoreDelta => ({
  newValue: 0,
  newRank: 'bad',
  isRight: false,
})

export const defaultPlayerScore = () => ({
  name: '',
  matchScore: defaultMatchScore(),
  playlistScores: {},
  trackScores: {},
})
export const defaultGamerStatus = (
  gamerIndex: number,
  gamerId = '',
  isActive = false,
  trackInfo?: PlayStatusTrackInfo
): GamerStatus => ({
  gamerId,
  playerName: gamerId,
  playStatus: defaultMatchScore(trackInfo),
  isActive: isActive,
  lastBlast: {
    isRight: false,
    isPressed: false,
    blastRank: 'bad',
  },
})

export const defaultMatchInfo = (): MatchInfo => ({
  slug: '',
  title: '',
  guestOrder: [],
  playlistOrder: [],
  playlists: {},
  isShareable: true,
})

export const defaultMatchStatus = (): MatchStatus => ({
  info: defaultMatchInfo(),
  invites: {},
  leaderboard: [],
  players: {},
})

const defaultDirtyState = {
  isAudioDirty: false,
  isInfoDirty: false,
  isLyricsDirty: false,
  isTimingDirty: false,
  isImageDirty: false,
}

export const defaultPlaylistInfo: PlaylistInfo = {
  slug: '',
  // owner: 'Unknown',
  title: 'Untitled',
  trackOrder: [],
  wordCount: 0,
  timestamp: 0,
  perTrackPlayLimit: -1,
}

export const defaultTrackInfo: TrackInfo = {
  slug: '',
  artist: 'Unknown',
  title: 'Unattributed',
  owner: 'Unknown',
  timedWordCount: 0,
  wordCount: 0,
  timestamp: 0,
  duration: 0,
  maxPoints: 0,
  links: '',
}

//
// export const defaultExtendedTrackInfo = {
//   ...defaultTrackInfo,
//   version: CURRENT_LAT_VERSION,
//   paths: {
//     infoUrl: '',
//     textUrl: '',
//     audioUrl: '',
//     lrcUrl: '',
//     imageUrl: '',
//   },
//   timestamps: {
//     remoteAudioTimestamp: 0,
//     remoteImageTimestamp: 0,
//     remoteLyricsTimestamp: 0,
//     remoteTimingTimestamp: 0,
//   },
// }
export const defaultModelPart: ModelPart = {
  eventID: 0,
  label: '',
  elem: null,
  isSection: false,
  isLine: false,
  referenceTime: null,
  time: null,
  score: undefined,
  index: -1,
}

const PLAYLIST_TYPES = {
  FEATURED: 'featured',
  OWNER: 'owned',
  USER: 'user',
}

const groupIndicators = {
  [PLAYLIST_TYPES.FEATURED]: 'Featured',
  [PLAYLIST_TYPES.OWNER]: 'My Tracks',
}

const DEFAULT_PLAYLIST_KEY = 'default'
const DEFAULT_PLAYLIST_TITLE = 'All Tracks'
const DEFAULT_PLAYLIST = {
  slug: DEFAULT_PLAYLIST_KEY,
  title: DEFAULT_PLAYLIST_TITLE,
  tracks: [],
}
const DEFAULT_PLAYLIST_MAP = {
  [DEFAULT_PLAYLIST_KEY]: DEFAULT_PLAYLIST,
}
const DEFAULT_PLAYLIST_ORDER = [DEFAULT_PLAYLIST_KEY]

const DEFAULT_PREROLL_SECS = 2.0
const MINIMUM_VIZ_SWITCH_PART_DURATION = 5.0 // seconds
const BLAST_ASSIST_DELAY_MILLIS = 1000
const VIZ_SWITCH_IN_DELAY_SECS = 0.7
const VIZ_SWITCH_OUT_EARLY_SECS = 3.0
const VIZ_CONTAINER_CSS_PREFIX = 'viz_'
const MINIMUM_SECTION_SWITCH_DURATION_SECS = 5.0

const alertSounds = ['bottle', 'funk', 'glass', 'morse', 'pop', 'purr', 'submarine', 'tink']

const userStatuses = {
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
}

const OFFLINE = 0
const ONLINE = 1
const PLAYING = 2
const PAUSED = 3
const AWAY = 4
const PEER_ONLINE_STATUS = { OFFLINE, ONLINE, PLAYING, PAUSED, AWAY }
const PEER_ONLINE_STATUS_TEXT = {
  [OFFLINE]: 'offline',
  [ONLINE]: 'online',
  [PLAYING]: 'playing',
  [PAUSED]: 'paused',
  [AWAY]: 'away',
}

const DEFAULT_LYRICS = '[Intro]\n[Verse 1]\nDouble-click to edit lyrics\n'
const BLAST_BUTTON_TITLE = 'click to Blast (or use Shift Key)'

// meh
const DEFAULT_TARGET = {
  sectionIndex: -1,
  lineIndex: -1,
  wordIndex: -1,
  elem: null,
}

const MAX_SHORT_ID_TRIES = 3
export const BLEND_MODES = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity',
]
export const MIN_FONT_SIZE = 9
export const MAX_FONT_SIZE = 102
export const DEFAULT_FONT_SIZE = 30
export const SECTION_FONT_SIZE_FACTOR = 0.6

export const COUNTDOWN_CLOCK_SIZE = 400
export const COUNTDOWN_CLOCK_SECONDS = 3

export const DEFAULT_BACKGROUNDS = [
  'bearing1',
  'bigbang2',
  'sailboat1',
  'splinters2',
  'simple1',
  'sunspots1',
]

export const SHOW_TRACK_GALLERY_TOOLTIP = 'open Track Gallery'
export const SHOW_LEADERBOARD_TOOLTIP = 'open Leaderboard'
export const SHOW_ROUND_DASHBOARD_TOOLTIP = 'open Round Dashboard'

export {
  API_PATH_S3_AUTH,
  API_PATH_SWITCH_USER_ALIAS,
  API_PATH_SPOTIFY_LOGIN,
  API_PATH_FETCH_SPOTIFY_TOKEN,
  BLAST_ASSIST_DELAY_MILLIS,
  BLAST_BUTTON_TITLE,
  BLASTER_URL_PREFIX,
  CHECK_USERNAME_AVAILABILITY_URL,
  CHECK_USERNAME_URL,
  CURRENT_LAT_VERSION,
  DEFAULT_AUDIO_TYPE,
  DEFAULT_AUDIO_FILE_TYPE,
  DEFAULT_IMAGE_TYPE,
  DEFAULT_IMAGE_FILE_TYPE,
  DEFAULT_LYRICS,
  DEFAULT_OWNED_MATCH_TITLE,
  DEFAULT_PLAYLIST_KEY,
  DEFAULT_PLAYLIST_MAP,
  DEFAULT_PLAYLIST_ORDER,
  DEFAULT_PLAYLIST_TITLE,
  DEFAULT_SLUG,
  DEFAULT_TARGET,
  HOW_TO_PROMPT,
  HOST_VISIBILITY_DEMO,
  HOST_VISIBILITY_HIDE,
  HOST_VISIBILITY_PLAY,
  PLAYER_ICONS_PATH,
  INIT_USERNAME_URL,
  IS_LOCAL_DEV,
  MAX_USER_ALIASES,
  MAX_SHORT_ID_TRIES,
  MAX_URL_LENGTH,
  MINIMUM_VIZ_SWITCH_PART_DURATION,
  MINIMUM_SECTION_SWITCH_DURATION_SECS,
  DEFAULT_PREROLL_SECS,
  PART_ID_PREFIX,
  PLEASE_VERIFY_EMAIL_URL,
  PEER_ONLINE_STATUS,
  PEER_ONLINE_STATUS_TEXT,
  PLAYLIST_TYPES,
  QUICKSTART_URL,
  RELEASE_NAME,
  S3_REGIONS,
  TERMS_OF_USE_URL,
  URL_PLACEHOLDER,
  USER_GUIDE_URL,
  VIZ_CONTAINER_CSS_PREFIX,
  VIZ_SWITCH_IN_DELAY_SECS,
  VIZ_SWITCH_OUT_EARLY_SECS,
  alertSounds,
  defaultDirtyState,
  defaultMatchScore,
  defaultScoreCounters,
  // defaultTrackInfo,
  groupIndicators,
  rankEnum,
  userStatuses,
}
