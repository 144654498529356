import React, { ChangeEvent, createRef, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'

import { DEFAULT_PLAYLIST_KEY, defaultPlaylistInfo } from '../../constants/constants'
import { updatePlaylist } from '../../actions/social/peer-playlists'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCurrentUser, selectOwnedPlaylistMap } from '../../selectors/blaster-peer-selectors'
import { selectMatchTitle, selectPlaylistMap } from '../../selectors/match-selectors'
import { MinimalTrackInfo, TrackInfo } from '../../types'
import { getTrackPoints } from '../../util/score-utils'
import { onModalContainerRef } from '../../util/scrolling'
import { getTrackArtistAndTitle } from '../../util/track-utils'
import Util from '../../util/util'
import CloseIcon from '../widgets/CloseIcon'
import { NumberInputWithLabel, TextInputWithLabel } from '../widgets/TextInputWithLabel'

type Props = {
  matchSlug: string
  playlistSlug: string | null
  playlistTitle: string | null
  currTrackInfo?: TrackInfo
  workingTrackOrder?: string[]
  onClose: (needUpdate?: boolean) => void
}
type PlaylistTrack = MinimalTrackInfo & {
  id: string
  otherOwner: string
  duration: number
  wordCount: number
  trackPoints: number
  isChecked: boolean
  disabled: boolean
}

export enum SortBy {
  artist = 'artist',
  title = 'title',
  points = 'points',
}

const PlaylistModal = ({
  matchSlug,
  playlistSlug,
  playlistTitle,
  currTrackInfo,
  workingTrackOrder,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch()
  const currUser = useAppSelector(selectCurrentUser)
  const { tracks, username, allTracksPlaylistSlug } = currUser
  const matchTitle = useAppSelector(selectMatchTitle(username, matchSlug))
  const ownedPlaylistMap = useAppSelector(selectOwnedPlaylistMap(username))
  const playlistMap = useAppSelector(selectPlaylistMap(`${username}/${matchSlug}`))
  const isNew = !playlistSlug
  const isLocked = playlistSlug === DEFAULT_PLAYLIST_KEY
  const [isDirty, setIsDirty] = useState(false)
  const [sortTracksBy, setSortTracksBy] = useState(SortBy.points)
  const [workingPlaylistSlug] = useState(isNew ? Util.generateId() : playlistSlug)
  const [workingPlaylistTitle, setWorkingPlaylistTitle] = useState(
    isNew || !playlistTitle ? 'Untitled Playlist' : playlistTitle
  )
  const existingPlaylist = playlistMap[workingPlaylistSlug] || {}
  const { perTrackPlayLimit: existingPerTrackPlayLimit } = existingPlaylist
  const [workingPerTrackPlayLimit, setWorkingPerTrackPlayLimit] =
    useState(existingPerTrackPlayLimit)
  const initTracks = () => {
    const { trackOrder: allTracksTrackOrder = [] } = ownedPlaylistMap[allTracksPlaylistSlug]
    const { slug: currTrackSlug } = currTrackInfo || {}
    const defaultTrackOrder = isNew ? (currTrackSlug ? [currTrackSlug] : []) : []
    const workingPlaylistTrackOrder = defaultTrackOrder
    if (workingTrackOrder) {
      workingPlaylistTrackOrder.push(...workingTrackOrder)
    } else {
      const { trackOrder: existingPlaylistTrackOrder = [] } = existingPlaylist
      if (workingPlaylistTrackOrder) {
        defaultTrackOrder.push(...existingPlaylistTrackOrder)
      }
    }
    const orderedOtherTrackSlugs = allTracksTrackOrder.filter(
      (slug) => !workingPlaylistTrackOrder.includes(slug)
    )
    const getTrackInfo = (slug: string, isChecked: boolean) => {
      const trackInfo = slug === currTrackSlug ? currTrackInfo : tracks[slug]
      const { owner = '', duration = 0, wordCount = 0 } = trackInfo || {}
      const otherOwner = username === owner ? '' : owner
      const { artist, title } = getTrackArtistAndTitle(trackInfo)
      const trackPoints = wordCount * getTrackPoints(wordCount, duration)
      return {
        id: slug,
        artist,
        title,
        duration,
        wordCount,
        trackPoints,
        otherOwner,
        isChecked,
        disabled: isLocked,
      }
    }
    const currTracks = workingPlaylistTrackOrder.map((slug) => {
      return getTrackInfo(slug, true)
    })
    const otherTracks = orderedOtherTrackSlugs.map((trackSlug) => {
      return getTrackInfo(trackSlug, false)
    })
    return [...currTracks, ...otherTracks]
  }
  const [isTracksInited, setIsTracksInited] = React.useState(false)
  const [workingTracks, setWorkingTracks] = React.useState<PlaylistTrack[]>(initTracks())

  const checkBoxIdForSlug = (slug: string) => `playlist-track-${slug}`

  const sortTracks = () => {
    const checkedTracks: PlaylistTrack[] = []
    const uncheckedTracks: PlaylistTrack[] = []
    workingTracks.forEach((track) => {
      const { id } = track
      const checkbox = document.getElementById(checkBoxIdForSlug(id)) as HTMLInputElement
      const isChecked = checkbox && checkbox.checked
      const whichArray = isChecked ? checkedTracks : uncheckedTracks
      whichArray.push(track)
    })
    const sortedCheckedTracks = checkedTracks.sort(
      (trackInfoA: PlaylistTrack, trackInfoB: PlaylistTrack) => {
        const { title: titleA, artist: artistA } = getTrackArtistAndTitle(trackInfoA)
        const { title: titleB, artist: artistB } = getTrackArtistAndTitle(trackInfoB)
        if (sortTracksBy === SortBy.points) {
          return trackInfoB.trackPoints - trackInfoA.trackPoints
        } else {
          const trackA =
            sortTracksBy === SortBy.title ? `${titleA}-${artistA}` : `${artistA}-${titleA}`
          const trackB =
            sortTracksBy === SortBy.title ? `${titleB}-${artistB}` : `${artistB}-${titleB}`
          return trackA.localeCompare(trackB)
        }
      }
    )
    const combinedTracks = [...sortedCheckedTracks, ...uncheckedTracks]
    setWorkingTracks(combinedTracks)
  }

  // TODO: support songs appearing more than once in a playlist??
  const addTrack = ({
    id,
    artist,
    title,
    otherOwner,
    isChecked,
    disabled,
    trackPoints,
  }: PlaylistTrack) => {
    const checkboxId = checkBoxIdForSlug(id)
    return (
      <div key={id} className="sortable-list-item handle" data-id={id}>
        <input
          id={checkboxId}
          type="checkbox"
          className="trackMembership"
          defaultChecked={isChecked}
          disabled={disabled}
        />
        <label htmlFor={checkboxId} className="handle track">
          <div>{title}</div>
          <div className="artist">{artist}</div>
        </label>
        <div>{trackPoints}</div>
        {otherOwner && <div className="otherOwner">{otherOwner}</div>}
      </div>
    )
  }

  const toggleAllTracks = (event: ChangeEvent<HTMLInputElement>) => {
    const checkboxes = document.querySelectorAll('.trackMembership')
    ;[].forEach.call(checkboxes, function (checkbox: HTMLInputElement) {
      // HACK to support old browsers
      checkbox.checked = event.target.checked
    })
  }

  const onAddOrUpdatePlaylist = () => {
    const filteredTracks = workingTracks.filter(({ id }) => {
      const checkbox = document.getElementById(checkBoxIdForSlug(id)) as HTMLInputElement
      return checkbox && checkbox.checked
    })
    const trackOrder = filteredTracks.map(({ id }) => id)
    const playlistDef = {
      ...defaultPlaylistInfo,
      slug: workingPlaylistSlug,
      title: workingPlaylistTitle,
      perTrackPlayLimit: workingPerTrackPlayLimit,
      trackOrder,
      // TODO: wordCount, timedWordCount?!
    }
    const newTrackInfo = isNew ? currTrackInfo : undefined
    dispatch(updatePlaylist({ matchSlug, playlistDef, newTrackInfo }))
    setIsDirty(false)
    onClose(true)
  }
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkingPlaylistTitle(event.target.value)
    setIsDirty(true)
  }
  const onPerTrackPlayLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value)
    const workingLimit = isNaN(newLimit) || newLimit < 0 ? -1 : newLimit
    setWorkingPerTrackPlayLimit(workingLimit)
    setIsDirty(true)
  }
  const titleRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const titleElem = titleRef.current
    if (!isDirty && titleElem) {
      titleElem.setSelectionRange(0, 100)
      titleElem.focus()
    }
  })
  const setList = (tracks: PlaylistTrack[]) => {
    setWorkingTracks(tracks)
    if (isTracksInited) {
      setIsDirty(true)
    } else {
      setIsTracksInited(true)
    }
  }
  return (
    <div className="modalContainer playlistModal" ref={onModalContainerRef} tabIndex={0}>
      <CloseIcon onClose={onClose} />
      <div className="modalHeading">{`${isNew ? 'New Set' : 'Set Info'}`}</div>
      <div>
        <label htmlFor="playlistSlug">Blaster ID: </label>
        <input id="playlistSlug" type="text" readOnly value={workingPlaylistSlug} />
      </div>
      <div>
        <label htmlFor="matchTitle">Match: </label>
        <input id="matchTitle" type="text" readOnly value={matchTitle} />
      </div>
      <div>
        <TextInputWithLabel
          id="playlistTitle"
          label="Title"
          onRef={titleRef}
          value={workingPlaylistTitle}
          onBlur={onTitleChange}
        />
      </div>
      <div>
        <NumberInputWithLabel
          id="maxPlaysPerTrack"
          label="Max Plays Per Track"
          value={workingPerTrackPlayLimit}
          onBlur={onPerTrackPlayLimitChange}
        />
      </div>
      <div>
        <div>
          Tracks:<br></br>
          <span className="help">(check/uncheck to add/remove, drag to re-order)</span>
        </div>
        <div className="headerRow">
          {!isLocked && (
            <div>
              <input type="checkbox" id="toggle-all-tracks" onChange={toggleAllTracks} />
              <label htmlFor="toggle-all-tracks">All</label>
            </div>
          )}
          <button onClick={sortTracks}>Sort</button>
          <div>
            by
            <label className="radio">
              <input
                type="radio"
                name="sort"
                checked={sortTracksBy === SortBy.title}
                onChange={() => setSortTracksBy(SortBy.title)}
              />
              Title
            </label>
            <label className="radio">
              <input
                type="radio"
                name="sort"
                checked={sortTracksBy === SortBy.artist}
                onChange={() => setSortTracksBy(SortBy.artist)}
              />
              Artist
            </label>
            <label className="radio">
              <input
                type="radio"
                name="sort"
                checked={sortTracksBy === SortBy.points}
                onChange={() => setSortTracksBy(SortBy.points)}
              />
              Points
            </label>
          </div>
        </div>
        <ReactSortable
          className="playlistDisplay"
          animation="150"
          list={workingTracks}
          setList={setList}
        >
          {workingTracks.map(addTrack)}
        </ReactSortable>
      </div>
      <div>
        <button disabled={!isNew && !isDirty} onClick={onAddOrUpdatePlaylist}>
          {isNew ? 'Create' : 'Update'}
        </button>
      </div>
    </div>
  )
}

export default PlaylistModal
