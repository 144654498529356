import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'

import { CURRENT_LAT_VERSION } from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import { selectHasSpotifyToken } from '../../selectors/session-selectors'
import { selectUserTracksInfo } from '../../selectors/blaster-peer-selectors'
import { getSpotifyUriFromTrackInfo, ownerForPath, userAssetsBase } from '../../util/track-utils'
import {
  getTrackImage,
  getTrackInfo,
  getTrackText,
  getTrackTiming,
  setAudio,
} from './get-track-resources'
import processTrack from './processTrack'
import { loadSpotifyTrack } from './spotify'

const loadTrack = createAsyncThunk<
  Promise<any>,
  { slug: string; playlistOwner: string; forCache?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/track', async ({ slug: trackSlug, playlistOwner, forCache }, { dispatch, getState }) => {
  const state = getState()
  const hasSpotifyToken = selectHasSpotifyToken(state)
  const playlistOwnerTracksInfo = selectUserTracksInfo(playlistOwner)(state)
  const track = playlistOwnerTracksInfo[trackSlug]
  if (!track) {
    throw new Error(`can't find track [${trackSlug}] for ${playlistOwner}`)
  }
  const { title, artist, owner: trackOwner, wordCount = 0, timedWordCount = 0 } = track
  const owner = trackOwner || playlistOwner
  const filePath = `${userAssetsBase()}/${ownerForPath(owner)}/${trackSlug}/${trackSlug}`
  const paths = {
    infoUrl: `${filePath}.json`,
    textUrl: `${filePath}.txt`,
    audioUrl: filePath, // caller must add extension
    lrcUrl: `${filePath}.lrc`,
    imageUrl: '',
  }
  const defaultTrackInfo = {
    slug: trackSlug,
    owner,
    title,
    artist,
    links: '',
    paths,
    wordCount,
    timedWordCount,
    duration: 0,
    maxPoints: 0,
    timestamps: {
      remoteAudioTimestamp: 0,
      remoteLyricsTimestamp: 0,
      remoteTimingTimestamp: 0,
      remoteImageTimestamp: 0,
    },
    imageAttributes: {
      blendMode: null,
      theme: null,
    },
    timestamp: 0,
    version: CURRENT_LAT_VERSION,
  }
  const trackInfo = await dispatch(getTrackInfo(defaultTrackInfo)).unwrap()
  const {
    paths: { audioUrl, textUrl, lrcUrl, imageUrl },
  } = trackInfo
  const spotifyUri = getSpotifyUriFromTrackInfo(trackInfo) || ''
  const isUseSpotifyForAudio = hasSpotifyToken && spotifyUri
  const textPromise = dispatch(getTrackText({ textUrl }))
  const imagePromise = dispatch(getTrackImage({ imageUrl }))
  const lrcPromise = dispatch(getTrackTiming({ lrcUrl }))
  const spotifyAudioAction = loadSpotifyTrack(spotifyUri)
  const ourAudioAction = setAudio({ audioUrl, isLogLoadTime: !forCache })
  const audioPromise = isUseSpotifyForAudio
    ? dispatch(spotifyAudioAction)
    : dispatch(ourAudioAction)
  return Promise.all([audioPromise, imagePromise, textPromise, lrcPromise]).then(
    ([audioResult, _imageResult, lyricsResult, timingResult]) => {
      if (forCache) {
        return
      }
      const audioDuration = unwrapResult(audioResult) as number
      const lyrics = unwrapResult(lyricsResult) as string
      const timing = unwrapResult(timingResult) as string
      return dispatch(processTrack({ trackSlug, trackInfo, lyrics, timing, audioDuration }))
    }
  )
})

export default loadTrack
