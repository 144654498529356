import auth0, { Auth0DecodedHash } from 'auth0-js'
import S3 from 'aws-sdk/clients/s3'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { updateUserStatus } from '../actions/social/update-play-followers'
import { initUserAlias, syncUserAlias } from '../actions/social/update-username'
import themes from '../components/themes'
import {
  API_PATH_S3_AUTH,
  BLEND_MODES,
  CURRENT_LAT_VERSION,
  DEFAULT_AUDIO_TYPE,
  DEFAULT_IMAGE_TYPE,
  PLEASE_VERIFY_EMAIL_URL,
  S3_REGIONS,
  URL_PLACEHOLDER,
  userStatuses,
} from '../constants/constants'
import { AUTH_DOMAIN, AUTH_CLIENT_ID, SERVER_API_URL } from '../constants/environment'
import getState, { AppDispatch } from '../reducers'
import sessionSlice, { currentPrefsVersion } from '../reducers/sessionSlice'
import { selectDirtyState } from '../selectors/current-play-selectors'
import { selectLocalTracks } from '../selectors/local-authoring-selectors'
import {
  selectCurrentBackgroundBlendMode,
  selectCurrentThemeIndex,
} from '../selectors/session-selectors'
import { AudienceType, Options, PackageData } from '../types'
import {
  constructAudioUrl,
  constructImageUrl,
  getExtensionForFileType,
  utcDateAsPath,
} from '../util/track-utils'
import Util from '../util/util'
import localStorageInstance from './UserLocalStorage'

const apiURL = SERVER_API_URL
const authPropertyName = 'https://lyricblaster.com/auth'
const authMetadataKey = 'https://lyricblaster.com/metadata'
type AuthToken = {
  [authMetadataKey]: { currAliasIndex: number; aliases: string[] }
  name: string
  picture: any
}

const bucketNames = {
  dev: 'dev2.lyricblaster.com',
  test: 'test.lyricblaster.com',
  prod: 'users.lyricblaster.com',
}
const simpleUploadMaxSize = 5 * 1024 * 1024
const maxUploadSize = simpleUploadMaxSize * 5

type Prefs = {
  version: number
  quickStart: {
    isShowOnStartup: boolean
  }
  termsOfUse: {
    userHasAgreed: boolean
  }
  audience: AudienceType
  theme: number
  latencyMillis: number
  isUseLatency: boolean
  defaultMode: string // 'viz' | 'play' | 'mod' | 'spell' | 'sticky'
  lastTrackUri: string
}
type InitPayload = {
  userAlias?: string
  currAliasIndex: number
  email: string
  picture: string
  aliases: string[]
}

class UserManager {
  private authDomain: string
  private authClientID: string
  private _s3: S3 | null
  private nickname: string
  private _accessToken: string
  email: string
  picture: string
  page: HTMLElement | null
  userHasSeenQuickStart: boolean
  prefs: Prefs
  options: Options
  _dispatch: AppDispatch | null
  webAuth: auth0.WebAuth

  constructor() {
    this._dispatch = null
    this.options = {
      contentVariant: 'dev',
      clearStorage: false,
    }
    this.authDomain = AUTH_DOMAIN
    this.authClientID = AUTH_CLIENT_ID
    this._s3 = null

    this.webAuth = new auth0.WebAuth({
      domain: this.authDomain,
      clientID: this.authClientID,
      responseType: 'token id_token',
      scope: 'openid profile',
      redirectUri: window.location.origin,
      audience: 'test1',
    })
    this._accessToken = ''
    this.nickname = ''
    this.email = ''
    this.picture = ''
    this.page = document.getElementById('page')
    this.userHasSeenQuickStart = false
    this.prefs = {
      version: currentPrefsVersion,
      quickStart: {
        isShowOnStartup: true,
      },
      termsOfUse: {
        userHasAgreed: false,
      },
      theme: 0,
      latencyMillis: 0,
      isUseLatency: true,
      defaultMode: 'viz',
      audience: 'solo',
      lastTrackUri: '',
    }
  }
  get dispatch() {
    if (!this._dispatch) {
      throw new Error('_dispatch not initialized!')
    }
    return this._dispatch
  }

  init(dispatch: AppDispatch, options: Options) {
    this._dispatch = dispatch
    this.options = options
    this.webAuth.parseHash(this._init.bind(this))
  }

  get userHasAgreedToTermsOfUse() {
    return this.prefs.termsOfUse.userHasAgreed
  }

  get latencyMillis() {
    return this.prefs.isUseLatency ? this.prefs.latencyMillis : 0
  }

  get lastTrackUri() {
    return this.prefs.lastTrackUri ? this.prefs.lastTrackUri : '/'
  }
  _updatePrefs() {
    localStorage.setItem(this.prefsKey, JSON.stringify(this.prefs))
    this.dispatch(sessionSlice.actions.setPrefs({ ...this.prefs }))
  }
  set isQuickStart(isQuickstart: boolean) {
    this.prefs.quickStart = { isShowOnStartup: isQuickstart }
    this._updatePrefs()
  }
  set theme(themeIndex: number) {
    this.prefs.theme = themeIndex
    this._updatePrefs()
  }
  get audience() {
    return this.prefs.audience
  }
  set audience(audience: AudienceType) {
    this.prefs.audience = audience
    this._updatePrefs()
  }
  set defaultMode(defaultMode: string) {
    this.prefs.defaultMode = defaultMode
    this._updatePrefs()
  }
  set termsOfUse(userHasAgreed: boolean) {
    this.prefs.termsOfUse = { userHasAgreed }
    this._updatePrefs()
  }
  set lastTrackUri(lastTrackUri: string) {
    this.prefs.lastTrackUri = lastTrackUri
    this._updatePrefs()
  }
  set latency({ isUseLatency, latencyMillis }: { isUseLatency: boolean; latencyMillis: number }) {
    this.prefs.latencyMillis = latencyMillis
    this.prefs.isUseLatency = isUseLatency
    this._updatePrefs()
  }
  updatePrefs(changedPrefs: Prefs) {
    if ('quickStart' in changedPrefs) {
      this.prefs.quickStart = changedPrefs.quickStart
    }
    if ('termsOfUse' in changedPrefs) {
      this.prefs.termsOfUse = changedPrefs.termsOfUse
    }
    if ('audience' in changedPrefs) {
      this.prefs.audience = changedPrefs.audience
    }
    if ('theme' in changedPrefs) {
      this.prefs.theme = changedPrefs.theme
    }
    if ('defaultMode' in changedPrefs) {
      this.prefs.defaultMode = changedPrefs.defaultMode
    }
    if ('latencyMillis' in changedPrefs) {
      this.prefs.latencyMillis = changedPrefs.latencyMillis
    }
    if ('isUseLatency' in changedPrefs) {
      this.prefs.isUseLatency = changedPrefs.isUseLatency
    }
    if ('lastTrackUri' in changedPrefs) {
      this.prefs.lastTrackUri = changedPrefs.lastTrackUri
    }
    localStorage.setItem(this.prefsKey, JSON.stringify(this.prefs))
    this.dispatch(sessionSlice.actions.setPrefs({ ...this.prefs }))
  }

  _init(err: any, authResult: Auth0DecodedHash | null) {
    window.location.hash = ''
    const stashPath = () => {
      if (window.location.pathname.length > 1) {
        localStorageInstance.lastRequestedPath = window.location.pathname // remember path for after auth redirect!
      }
    }
    if (err) {
      console.log(err)
      const { errorDescription = '' } = err
      if (errorDescription.indexOf('email') >= 0) {
        window.location.href = PLEASE_VERIFY_EMAIL_URL
      } else {
        alert(`Unknown authentication error: ${errorDescription}`)
        // stashPath()
        this.logout()
      }
      return
    }
    const finishInit = ({ currAliasIndex, email, picture, aliases }: InitPayload) => {
      const userAlias = aliases[currAliasIndex]
      if (!userAlias) {
        throw new Error('empty user alias')
      }
      this.nickname = userAlias
      this.email = email
      this.picture = picture
      localStorageInstance.username = this.nickname

      const rawPrefs = localStorage.getItem(this.prefsKey)
      if (rawPrefs) {
        const savedPrefs = JSON.parse(rawPrefs)
        if (savedPrefs.version < 2) {
          savedPrefs.termsOfUse = this.prefs.termsOfUse
        }
        if (savedPrefs.version < 4) {
          savedPrefs.defaultMode = this.prefs.defaultMode
        }
        if (savedPrefs.version < 5) {
          savedPrefs.latencyMillis = this.prefs.latencyMillis
          savedPrefs.isUseLatency = this.prefs.isUseLatency
        }
        if (savedPrefs.version < 6) {
          savedPrefs.audience = this.prefs.audience
        }
        savedPrefs.version = currentPrefsVersion
        this.prefs = savedPrefs
      }
      this.dispatch(
        sessionSlice.actions.initSession({
          isInitialized: true,
          username: this.username,
          currentBlaster: this.username,
          prefs: { ...this.prefs },
          userAliases: aliases,
          currUserAliasIndex: currAliasIndex,
        })
      )
      this.initS3()
    }

    if (authResult) {
      const {
        accessToken = '',
        idToken = '',
        expiresIn = 0,
        idTokenPayload: { nickname, picture, name: email, [authMetadataKey]: metadata = {} },
      } = authResult
      const { isEnabled: isAccountEnabled = false, aliases = [], currAliasIndex = 0 } = metadata
      this.accessToken = accessToken
      const currentUserAlias =
        currAliasIndex < aliases.length ? aliases[currAliasIndex] : Util.sluggify(nickname)
      const rememberAuthInfo = (userAlias: string) => {
        const expirationMillis = expiresIn * 1000
        const expiresAt = JSON.stringify(expirationMillis + new Date().getTime())
        localStorage.setItem('access_token', accessToken)
        localStorage.setItem('id_token', idToken)
        localStorage.setItem('expires_at', expiresAt)
        localStorage.setItem('user_nickname', userAlias)
        localStorage.setItem('user_picture', picture)
        document.cookie = `accessToken=${accessToken}; max-age=${expirationMillis}`
      }
      const userAliasAction = isAccountEnabled ? syncUserAlias : initUserAlias
      this.dispatch(userAliasAction({ username: currentUserAlias, isResolveCollision: true })).then(
        (payload) => {
          console.log('payload', payload)

          const userAlias = payload.payload as string // WTF
          rememberAuthInfo(userAlias)
          if (!isAccountEnabled) {
            aliases.push(userAlias)
          }
          finishInit({ userAlias, email, picture, aliases, currAliasIndex })
        }
      )
    } else {
      const accessToken = localStorage.getItem('access_token')
      const expiresAt = parseInt(localStorage.getItem('expires_at') || '0')
      const isTokenExpired = Date.now() > expiresAt
      const { email, picture, aliases, currAliasIndex = 0 } = this._getUserInfo()
      const currAlias =
        aliases.length > currAliasIndex && currAliasIndex >= 0 ? aliases[currAliasIndex] : ''
      if (currAlias && email && accessToken && !isTokenExpired) {
        this.accessToken = accessToken
        finishInit({ email, picture, aliases, currAliasIndex })
      } else {
        stashPath()
        this.webAuth.authorize()
      }
    }
  }

  get username() {
    return `@${this.nickname}`
  }

  callAPI(endpoint: string, secured: boolean) {
    return new Promise((resolve, reject) => {
      const url = `${apiURL}${endpoint}`
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      if (secured) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken)
      }
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText))
        } else {
          reject(xhr.statusText)
        }
      }
      xhr.send()
    })
  }

  logout() {
    this.dispatch(updateUserStatus(userStatuses.LOGGED_OUT))
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('user_nickname')
    localStorage.removeItem('user_picture')
    document.cookie = 'accessToken='

    const logoutRedirectURL = `https://${this.authDomain}/logout?client_id=${this.authClientID}&returnTo=${window.location.origin}`

    window.location.href = logoutRedirectURL
  }

  get accessToken() {
    return this._accessToken
  }

  set accessToken(accessToken) {
    this._accessToken = accessToken
  }

  get spotifyAccessToken() {
    return localStorage.getItem('spotify_access_token') || ''
  }

  set spotifyAccessToken(accessToken: string) {
    localStorage.setItem('spotify_access_token', accessToken)
  }

  get isPlaylistAdmin() {
    const decodedToken = jwtDecode(this.accessToken)
    // @ts-ignore
    const auth = decodedToken[authPropertyName]
    if (auth && auth.roles && auth.roles.includes('playlist-admin')) {
      return true
    }

    return false
  }

  initS3() {
    const config = {
      'content-type': 'application/json',
      headers: { authorization: `Bearer ${this.accessToken}` },
    }
    axios
      .get(API_PATH_S3_AUTH, config)
      .then((response) => {
        const { role, img, nickname } = response.data
        const region = !nickname.length ? S3_REGIONS[0] : nickname
        const newS3 = new S3({
          accessKeyId: role,
          secretAccessKey: img,
          region,
        })
        this._s3 = newS3
      })
      .catch((err) => {
        console.log(err)
      })
  }
  get s3() {
    if (this._s3) {
      return this._s3
    }
    throw new Error('s3 accessed before initialized')
  }
  _getUserInfo() {
    try {
      const idToken = localStorage.getItem('id_token') || ''
      const decodedToken = jwtDecode(idToken) as AuthToken
      const {
        [authMetadataKey]: { currAliasIndex = 0, aliases = [] },
        name: email,
        picture,
      } = decodedToken
      return { currAliasIndex, aliases, email, picture }
    } catch (err) {
      console.warn('_getUserInfo failed', err)
      return { aliases: [] }
    }
  }

  get prefsKey() {
    return `${this.nickname}-prefs`
  }

  get s3UserFolders() {
    // TODO: move to member variable
    const username = this.nickname
    return {
      dev: `users/${username}`,
      test: `users/${username}`,
      prod: `${username}`,
    }
  }

  get s3DatedTrackFolders() {
    // TODO: move to member variable
    const datePath = utcDateAsPath()
    return {
      dev: `tracks/${datePath}`,
      test: `tracks/${datePath}`,
      prod: `tracks/${datePath}`,
    }
  }

  get s3DatedImageFolders() {
    // TODO: move to member variable
    const datePath = utcDateAsPath()
    return {
      dev: `images/${datePath}`,
      test: `images/${datePath}`,
      prod: `images/${datePath}`,
    }
  }

  get s3UserDataHome() {
    const host = 'https://s3.amazonaws.com'
    const userFolders = this.s3UserFolders
    return {
      slug: this.username,
      type: 'aws-s3',
      urls: {
        dev: `${host}/${bucketNames.dev}/${userFolders.dev}`,
        test: `${host}/${bucketNames.test}/${userFolders.test}`,
        prod: `${host}/${bucketNames.prod}/${userFolders.prod}`,
      },
    }
  }

  getS3BucketParams(trackSlug: string, fileType: string, file: any) {
    const {
      options: { contentVariant },
    } = this
    const isAudio = fileType.startsWith('audio')
    const isImage = fileType.startsWith('image')
    const folderPath = isAudio
      ? this.s3DatedTrackFolders[contentVariant]
      : isImage
      ? this.s3DatedImageFolders[contentVariant]
      : `${this.s3UserFolders[contentVariant]}/${trackSlug}`
    const fileName = isAudio || isImage ? `${this.nickname}-${trackSlug}` : trackSlug
    const extension = getExtensionForFileType(fileType)
    const filePath = `${folderPath}/${fileName}.${extension}`
    const params = {
      Bucket: bucketNames[contentVariant],
      Key: filePath,
      Body: file,
      ContentType: fileType,
      // ACL: 'public-read',
    }
    return params
  }

  doS3Upload(params: Object, progressCallback: any) {
    return new Promise<string>((resolve, reject) => {
      this._doS3Upload(params, progressCallback, resolve, reject)
    })
  }
  _doS3Upload(params: any, progressCallback: any, successCallback: any, errorCallback: any) {
    let upload
    const size = params.Body.size

    if (!size || size < simpleUploadMaxSize) {
      upload = this.s3.upload(params)
    } else if (size < maxUploadSize) {
      upload = new S3.ManagedUpload({
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        service: this.s3,
        params: params,
      })
    } else {
      const err = new Error(`${size} exceeds max upload size of ${maxUploadSize}`)
      console.warn(err.message)
      if (errorCallback) {
        errorCallback(err)
      }
      return
    }

    upload
      .on('httpUploadProgress', function (evt) {
        const percentUploaded = parseInt(String((evt.loaded * 100) / evt.total))
        if (progressCallback) {
          progressCallback(percentUploaded)
        }
      })
      .send(function (err: Error, data: S3.ManagedUpload.SendData) {
        if (err) {
          console.warn(err)
          if (errorCallback) {
            errorCallback(err)
          }
        } else {
          const remoteUrl = data.Location
          if (successCallback) {
            successCallback(remoteUrl)
          }
        }
      })
  }

  getPackageData({
    trackSlug,
    localAudioFileType,
    localImageFileType,
    cloneFrom,
  }: {
    trackSlug: string
    localAudioFileType: string
    localImageFileType: string
    cloneFrom: string
  }) {
    const state = getState()
    const { isAudioDirty, isLyricsDirty, isTimingDirty, isImageDirty } = selectDirtyState(state)
    const localAudioExtension = getExtensionForFileType(localAudioFileType)
    const localImageExtension = getExtensionForFileType(localImageFileType)
    const localTracks = selectLocalTracks(state)
    const localTrack = localTracks[trackSlug]
    const blendMode = BLEND_MODES[selectCurrentBackgroundBlendMode(state)]
    const themeName = themes[selectCurrentThemeIndex(state)].name

    if (!localTrack) {
      console.log(`slug not in local tracks: ${trackSlug}`)
      return
    }

    const now = Date.now()
    const {
      remoteLyricsTimestamp = now,
      remoteAudioTimestamp = now,
      remoteTimingTimestamp = now,
      remoteImageTimestamp = now,
      remoteLyricsPath = '',
      remoteTimingPath = '',
      remotePath: remoteAudioPath = '',
      remoteImagePath = '',
      artist = '',
      title = 'Untitled',
      wordCount = 0,
      timedWordCount = 0,
      duration = 0,
    } = localTrack
    const { urls: homeUrls, slug: defaultHome } = this.s3UserDataHome
    const audioTimestamp = isAudioDirty || !remoteAudioTimestamp ? now : remoteAudioTimestamp
    const imageTimestamp = isImageDirty || !remoteImageTimestamp ? now : remoteImageTimestamp
    const lyricTimestamp = isLyricsDirty || !remoteLyricsTimestamp ? now : remoteLyricsTimestamp
    const timingTimestamp = isTimingDirty || !remoteTimingTimestamp ? now : remoteTimingTimestamp
    const remoteAudioExtension = remoteAudioPath
      ? remoteAudioPath.substring(remoteAudioPath.lastIndexOf('.') + 1)
      : ''
    const audioType = localAudioExtension || remoteAudioExtension || DEFAULT_AUDIO_TYPE
    const remoteImageExtension = remoteImagePath
      ? remoteImagePath.substring(remoteImagePath.lastIndexOf('.') + 1)
      : ''
    const imageType = localImageExtension || remoteImageExtension || DEFAULT_IMAGE_TYPE
    const getUrlOrDefault = (path: string, isAudio = false, isImage = false) => {
      if (!path) {
        return ''
      }
      if (isAudio) {
        const defaultAudioUrl = localAudioFileType
          ? path
          : constructAudioUrl({
              timestamp: audioTimestamp,
              owner: cloneFrom || this.username,
              slug: trackSlug,
              type: audioType,
            })
        return path === defaultAudioUrl ? URL_PLACEHOLDER : path
      }
      if (isImage) {
        const defaultImageUrl = localImageFileType
          ? path
          : constructImageUrl({
              timestamp: imageTimestamp,
              owner: cloneFrom || this.username,
              slug: trackSlug,
              type: imageType,
            })
        return path === defaultImageUrl ? URL_PLACEHOLDER : path
      }
      const homeUrl = homeUrls[this.options.contentVariant]
      return cloneFrom || path.startsWith(homeUrl) ? URL_PLACEHOLDER : path
    }
    const getImageArray = () => {
      return remoteImagePath || isImageDirty
        ? [
            {
              type: localImageExtension || remoteImageExtension || DEFAULT_IMAGE_TYPE,
              url: getUrlOrDefault(remoteImagePath, false, true),
              timestamp: imageTimestamp,
              home: cloneFrom || '',
              blendMode,
              theme: themeName,
            },
          ]
        : []
    }
    const packageData: PackageData = {
      version: CURRENT_LAT_VERSION,
      slug: trackSlug,
      home: defaultHome,
      artist,
      title,
      attributes: {
        wordCount,
        timedWordCount,
        duration,
        tempo: '',
        tags: [],
      },
      media: {
        audio: [
          {
            mix: 'all',
            type: localAudioExtension || remoteAudioExtension || DEFAULT_AUDIO_TYPE,
            url: getUrlOrDefault(remoteAudioPath, true),
            timestamp: audioTimestamp,
            home: '',
          },
        ],
        score: [
          {
            type: 'lyrics',
            url: getUrlOrDefault(remoteLyricsPath),
            timing: [
              {
                type: 'timing',
                url: getUrlOrDefault(remoteTimingPath),
                timestamp: timingTimestamp,
              },
            ],
            timestamp: lyricTimestamp,
          },
        ],
        image: getImageArray(),
        links: localTrack.links,
      },
      timestamp: now,
    }

    if (cloneFrom) {
      packageData.media.audio[0].home = cloneFrom
    }
    return packageData
  }
}

const defaultUserManager = new UserManager()
export default defaultUserManager
